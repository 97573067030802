import React, { useState, useEffect } from "react";
import "../css/accomadation.css";

import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

import MainImage from "../../../../assets/images/accommodation/main.png";
import Van from "../../../../assets/images/accommodation/van.png";
import Cross from "../../../../assets/images/accommodation/Cross.png";
import Prize from "../../../../assets/images/accommodation/prize.png";
import Axios from "axios";

const HomeAccomadation = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [cl, setCl] = useState(null);
  const navigate = useNavigate();

  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : null
  );

  console.log(user);

  const handleBookNowClick = () => {
    if (user) {
      setIsFormVisible(true);
    } else {
      navigate(`/register?redirect=accommodation`);
      setIsFormVisible(false);
    }
  };

  const handlePayment = (e) => {
    window.location.href = "https://konfhub.com/moodi";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = ["age", "aadharId", "collegeIdUrl"];
    const isAnyFieldEmpty = requiredFields.some((field) => !accoObj[field]);

    if (isAnyFieldEmpty) {
      alert("Please fill in all fields before submitting.");
      return;
    }
    if (!termsChecked) {
      alert("Please agree to the terms and conditions before submitting.");
      return;
    }
    setSubmitDisabled(true);
    Axios.post(
      "https://ashish.moodi.org/acco/acco_reg",
      {
        user: user.userId,
        age: accoObj.age,
        aadharId: accoObj.aadharId,
        collegeIdUrl: accoObj.collegeIdUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          window.alert("Registered Successfully!!");
          window.location.reload();
          setSubmitDisabled(false);
        } else {
          window.alert(res.data.status);
          setSubmitDisabled(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchData = (req, res, next) => {
    Axios.post(
      "https://ashish.moodi.org/acco/getinfo",
      {
        user: user.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setAccoObj(res.data);
        console.log(res.data);
        if (res.data.age) {
          setRegistered(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchClData = () => {
    Axios.get(`https://ashish.moodi.org/cl/fetch_cl?clg=${user.collegeId}`)
      .then((res) => {
        if (res.data.length > 0) {
          setCl(res.data[0]);
        } else {
          setCl(null);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleCheckboxChange = (e) => {
    setTermsChecked(e.target.checked); // Update the checkbox state
  };

  const handleBookClose = () => {
    setIsFormVisible(false);
  };

  const [accoObj, setAccoObj] = useState({
    name: "",
    state: "",
    city: "",
    college: "",
    phone: "",
    email: "",
    age: "",
    gender: "",
    yearOfStudy: "",
    MI_NO: "",
    aadharId: "",
    collegeIdUrl: "",
  });

  const onChange = (e) => {
    setAccoObj({ ...accoObj, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (user) {
      fetchData();
      fetchClData();
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="pageOne">
      <p className="main_head_acc">ACCOMMODATION</p>
      <img src={Prize} alt="prize" className="ear_acc_p" />
      <div className="component_two">
        <div className="acco_des_cont">
          <div className="acco_des">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
          <div className="acco_des">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
          <div className="acco_des">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
        </div>
        <div className="accon_conta">
          <p className="acco_head_a">
            Register for Accommodation at Mood Indigo from 24th to 27th
            December, 2024
          </p>
          <p className="acco_head_b">
            Welcome to the 54th edition of Mood Indigo, IIT Bombay's cultural
            festival—Asia's largest college fest! Renowned for hosting the most
            international artists at a single event, Mood Indigo continues to
            grow, year after year, as a completely student-driven spectacle.
          </p>
          <p className="acco_head_b">
            We’re committed to delivering an unforgettable experience, making
            these the best days of your life. Book your accommodation to
            experience the amazing energy, vibrant performances, and
            unforgettable moments that Mood Indigo has in store for you!
          </p>
          <p className="acco_head_b note_acco">
            Note: *Due to high demand, accommodation will be on campus or at a
            partner hotel, at the discretion of the Mood Indigo team. We'll make
            every effort to keep contingent members together.*
          </p>
        </div>
        <div className="acco_des_cont">
          <div className="acco_des rot">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
          <div className="acco_des rot">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
          <div className="acco_des rot">
            <div className="custom-box"></div>
            <div className="new_custom"></div>
          </div>
        </div>
      </div>
      <div className="button_container">
        {/* <button
          className="book_button"
          type="button"
          onClick={registered ? null : handleBookNowClick} // Disable onClick if registered
        >
          {registered ? "Register Successfully" : "Book Now"}
        </button> */}
        <button className="book_button">Accommodation close</button>
        {/* <button className="book_button">Phase two will start soon</button> */}
      </div>
      {isFormVisible && (
        <div className="form_container">
          <form className="accommodation_form" onSubmit={handleSubmit}>
            <img
              src={Cross}
              alt="cross"
              className="cross_image"
              onClick={handleBookClose}
            />
            <p className="acco_heading">Pre-Register For Accommodation</p>
            <div className="acco_arrange">
              <div className="acco_a">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={accoObj.userName}
                  readonly
                />
              </div>
              <div className="acco_a">
                <label>College</label>
                <input
                  type="text"
                  placeholder="Enter your college"
                  name="college"
                  value={accoObj.collegeName}
                  readonly
                />
              </div>
              <div className="acco_a">
                <label>State</label>
                <input
                  type="text"
                  placeholder="Enter your state"
                  name="state"
                  value={accoObj.state}
                  readonly
                />
              </div>
            </div>
            <div className="acco_arrange">
              <div className="aco_ext">
                <label>Mobile No.</label>
                <input
                  type="tel"
                  placeholder="Enter your mobile number"
                  name="phone"
                  value={accoObj.phone}
                  readonly
                />
              </div>
              <div className="aco_ext">
                <label>Email ID</label>
                <input
                  type="email"
                  placeholder="Enter your email ID"
                  name="email"
                  value={accoObj.email}
                  readonly
                />
              </div>
              <div className="age_input">
                <label>Age</label>
                <input
                  type="number"
                  placeholder="Enter your age"
                  name="age"
                  onChange={onChange}
                  value={accoObj.age}
                  required
                />
              </div>
              <div className="aco_ext">
                <label>Gender</label>
                <select name="" id="" value={accoObj.gender} disabled>
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="acco_arrange ext_acco_arra">
              <div className="acco_a">
                <label>Year of Study</label>
                <select name="" id="" value={accoObj.yearOfStudy} disabled>
                  <option value="">Select year of Study</option>
                  <option value="First">First</option>
                  <option value="Second">Second</option>
                  <option value="Third">Third</option>
                  <option value="Fourth">Fourth</option>
                  <option value="Fifth">Fifth</option>
                </select>
              </div>
              <div className="acco_a">
                <label>MI Number</label>
                <input
                  type="text"
                  placeholder="Enter your MI number"
                  name="MI_NO"
                  readonly
                  value={accoObj.MI_NO}
                />
              </div>
              <div className="acco_a">
                <label>College ID Drive Link</label>
                <input
                  type="url"
                  placeholder="Enter your college ID drive link"
                  name="collegeIdUrl"
                  onChange={onChange}
                  value={accoObj.collegeIdUrl}
                  required
                />
              </div>
            </div>
            <div className="acco_arrange">
              <div className="acco_a">
                <label>Aadhar/PAN ID Drive Link</label>
                <input
                  type="url"
                  placeholder="Enter your Aadhar/PAN ID drive link"
                  name="aadharId"
                  onChange={onChange}
                  value={accoObj.aadharId}
                  required
                />
              </div>
              <div className="terms_conditions">
                <p className="terms_head">
                  Upload your ID’s in a google drive and submit the links here,
                  with ‘open for all’ access.
                </p>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      className="checkbox"
                      required
                      onChange={handleCheckboxChange}
                      checked={registered ? true : accoObj.terms}
                    />
                    <span className="check_head">
                      I agree, the above provided information is the best to my
                      knowledge
                    </span>
                  </div>
                  {!registered && (
                    <button
                      type="submit"
                      value="Submit"
                      disabled={submitDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="bottom">
        <img className="bottom_img" src={MainImage} alt="main" />
        <img className="van" src={Van} alt="van" />
        <div className="bottom_head"></div>
      </div>
    </div>
  );
};

export default HomeAccomadation;
