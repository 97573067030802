import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ComingSoon from "./component/pages/ComingSoon";
import Home from "./component/pages/Home/IndexHome";
import Register from "./component/pages/Registration/RegistrationPage";
import SuccessPage from "./component/pages/Registration/SuccessPage";
import NavbarSec from "./component/small_component/NavbarSec";
import Ccp from "./component/pages/ccp/DashboardHome";
import ProtectedRoute from "./component/pages/context/ProtectLogin";
import Multicity from "./component/pages/multicity/IndexMulticity";
import CityPage from "./component/pages/multicity/multicityComponents/Citypage";
import HomeAccomadation from "./component/pages/accomadation/Index";
import Cl from "./component/pages/cl/IndexCl";
import Competition from "./component/pages/compi/IndexCompi";
import IAP from "./component/pages/iap/IndexIAP";
import GenrePage from "./component/pages/compi/component/GenrePage";
import Vogue from "./component/pages/Vogue";
import Cosplay from "./component/pages/Cosplay";
import Hysteria from "./component/pages/Hyteria";
import Sgtl from "./component/pages/Sgtl";
import Migt from "./component/pages/Migt";
function App() {
  return (
    <ProtectedRoute>
      <BrowserRouter>
        <MainContent />
      </BrowserRouter>
    </ProtectedRoute>
  );
}

function MainContent() {
  const location = useLocation();

  const hideNavbarPaths = ["/iapss"];

  const shouldShowNavbar = !hideNavbarPaths.includes(location.pathname);

  return (
    <>
      {shouldShowNavbar && <NavbarSec />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/ccp" element={<Home />} />
        <Route path="/competitions" element={<Competition />} />
        <Route path="/accommodation" element={<HomeAccomadation />} />
        <Route path="/cl" element={<Cl />} />
        <Route path="/vogue" element={<Vogue />} />
        <Route path="/cosplay" element={<Cosplay />} />
        <Route path="/hysteria" element={<Hysteria />} />
        <Route path="/sgtl" element={<Sgtl />} />
        <Route path="/mrandmiss" element={<Sgtl />} />
        <Route path="/migt" element={<Migt />} />
        <Route path="/iap" element={<IAP />} />
        <Route path="/merch" element={<ComingSoon />} />
        <Route path="/mi-merch" element={<ComingSoon />} />
        <Route path="/multicities" element={<Multicity />} />
        <Route path="/multicities/cityinfo/:city" element={<CityPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/ccp/mymi" element={<Ccp />} />
        <Route path="/competitions/genre/:genre" element={<GenrePage />} />
      </Routes>
    </>
  );
}

export default App;
