/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Random_assets from "./Registration/Random_assets";
import "./mimerch.css";
import Black from "../../assets/images/black.png";
import Rhtym from "../../assets/images/rhtym.png";
import White from "../../assets/images/white.png";

const ComingSoon = () => {
  return (
    <div>
      <Random_assets />
      <div className="merch">
        <p className="shop_text">SHOP</p>
        <div className="merch_shop_main">
          <div className="merch_one_container">
            <div className="merch_one">
              <div className="prinea">
                <p>
                  INR 299<span className="cross-price">399</span>
                </p>
              </div>
              <img src={Black} alt="thsir" className="tshirt-iamg" />
            </div>
            <div
              className="price_merch"
              onClick={() =>
                (window.location.href =
                  "https://tshirtsnearme.in/product/mood-indigo24-iit-bombay-black-t-shirt-unisex-hot-chai-cool-mood/")
              }
            >
              <p className="check_merch">Check It Out</p>
            </div>
          </div>
          <div className="merch_one_container">
            <div className="merch_one" style={{ backgroundColor: "black" }}>
              <div className="prinea">
                <p>
                  INR 299<span className="cross-price">399</span>
                </p>
              </div>
              <img src={White} alt="thsir" className="tshirt-iamg" />
            </div>
            <div
              className="price_merch"
              onClick={() =>
                (window.location.href =
                  "https://tshirtsnearme.in/product/mood-indigo24-iit-bombay-white-t-shirt-unisex-cultural-fusion-mood-indigo-edition/")
              }
            >
              <p className="check_merch">Check It Out</p>
            </div>
          </div>
          <div className="merch_one_container">
            <div className="merch_one">
              <div className="prinea">
                <p>
                  INR 299<span className="cross-price">399</span>
                </p>
              </div>
              <img src={Rhtym} alt="thsir" className="tshirt-iamg" />
            </div>
            <div
              className="price_merch"
              onClick={() =>
                (window.location.href =
                  "https://tshirtsnearme.in/product/mood-indigo24-iit-bombay-black-t-shirt-unisex-wear-the-rhythm-of-ethnicity/")
              }
            >
              <p className="check_merch">Check It Out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
