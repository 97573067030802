import React from "react";
import ComMainImage from "../../../../assets/images/compi/new_main.png";
import Trophy from "../../../../assets/images/compi/Trophy.svg";
import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
import Stage from "../../../../assets/images/compi/stage.svg";
import Light_a from "../../../../assets/images/compi/light_a.svg";
import Light from "../../../../assets/images/compi/light.svg";
import Group from "../../../../assets/images/compi/Group.png";
import Deadline from "../../../../assets/images/compi/dead_compi.svg";
import Mobile from "../../../../assets/images/compi/mobile.jpg";
import { Link } from "react-scroll";
import Faqs from "./Faqs";
import HallOfFame from "./HellofFame";
import Genre from "./Genre";

const HomeCom = () => {
  const text = [
    "REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW REGISTER NOW",
  ];
  const customStyle = {
    fontSize: "7vw",
    color: "black",
    fontWeight: "400",
    fontFamily: "Gasoek One",
    lineHeight: "1.18",
  };
  return (
    <div className="compi_main">
      <div className="compi_h_a">
        <img src={ComMainImage} alt="compi" className="compi_main_image" />
        <img src={Mobile} alt="compi" className="compi_main_image_m" />
        <div className="com_home">
          <p className="comp_head_a">COMPETITIONS</p>
          <p className="comp_head_b">MOOD INDIGO</p>
          <p className="comp_head_c">
            The Competitions at Mood Indigo, IIT Bombay have consistently been
            one of the biggest platforms in India for young artists in several
            colleges across the globe to showcase their talents.
          </p>
          <p className="comp_head_c">
            Every member of the competitions community not only awaits cash
            prizes and breath-taking LYP opportunities to kickstart their
            journey but also a lifetime of memories and the thrills that they
            experience at Mood Indigo
          </p>
        </div>
      </div>
      {/* <img src={Deadline} alt="dead" className="dead_compi" /> */}
      <Link to="genre" smooth={true} duration={500}>
        <div className="rolling_compi">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
      </Link>
      <div className="com_tro_head">
        <img src={Light_a} alt="light" className="light_a" />
        <img src={Light} alt="light" className="light_b" />
        <div className="com_tro_head_a">
          <p className="com_tro_a">IMAGINE A</p>
          <div className="com_tro_p">
            <h1 className="com_tro_b">STAGE</h1>
            <h1 className="com_tro_c">WHICH</h1>
            <h1 className="com_tro_d">GETS</h1>
          </div>
          <div className="com_tro_p">
            <p className="com_tro_e">YOU YOUR</p>
            <p className="com_tro_f">DREAMS</p>
          </div>
          <img src={Stage} alt="light" className="stage" />
        </div>
      </div>

      <div className="com_tro_head_b">
        <img src={Group} alt="light" className="group" />
        <div className="trophy_a">
          <div>
            <img src={Trophy} alt="trophy" className="trophy_image" />
            <p className="tro_head">Championship Point Structure</p>
          </div>
          <div className="trophy_heading">
            <p>
              The top participants and finalists of each competition will be
              allotted a certain number of points, with the group competitions
              carrying a higher weightage. At the end of all the competitions,
              the college with the maximum number of points becomes the
              overall champion
            </p>
          </div>
          <div className="table-containers">
            <table className="score-table">
              <thead>
                <tr>
                  <th></th>
                  <th>1st</th>
                  <th>2nd</th>
                  <th>3rd</th>
                  <th>4th</th>
                  <th>5th</th>
                  <th>Finalist</th>
                  <th>Participant</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Solo</td>
                  <td>30</td>
                  <td>18</td>
                  <td>12</td>
                  <td>9</td>
                  <td>6</td>
                  <td>3</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Team</td>
                  <td>90</td>
                  <td>54</td>
                  <td>36</td>
                  <td>27</td>
                  <td>18</td>
                  <td>9</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="genre">
        <Genre />
      </div>
      <HallOfFame />
      <Link to="genre" smooth={true} duration={500}>
        <div className="rolling_compi_sec ">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
      </Link>
      <Faqs />
    </div>
  );
};

export default HomeCom;
