import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Hysteria = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/competitions/genre/ab80e629-261b-4c38-b4e8-8be5dd72658e");
  }, []);
  return <div></div>;
};

export default Hysteria;
