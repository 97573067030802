import React from "react";
import Expo from "../../../../assets/images/compi/lyp/expo.jpg";
import HipHop from "../../../../assets/images/compi/lyp/hip_hop.jpg";
import Reft from "../../../../assets/images/compi/lyp/reft.jpg";
import Hum from "../../../../assets/images/compi/lyp/hum.jpg";
import Drama from "../../../../assets/images/compi/lyp/drama.jpg";
import Purple from "../../../../assets/images/compi/lyp/purple.jpg";
import Toast from "../../../../assets/images/compi/lyp/toast.jpg";
import Logo from "../../../../assets/images/compi/lyp/logo.svg";
import Kjab from "../../../../assets/images/compi/lyp/kgaf.jpg";
import IAF from "../../../../assets/images/compi/lyp/IAF.jpg";
import PA from "../../../../assets/images/compi/lyp/pa.jpg";
import JF from "../../../../assets/images/compi/lyp/jf.jpg";
import Iphoto from "../../../../assets/images/compi/lyp/IphotF.jpg";
import Nim_one from "../../../../assets/images/compi/lyp/nim_one.avif";
import Nim_two from "../../../../assets/images/compi/lyp/nim_two.png";
import MM from "../../../../assets/images/compi/lyp/MM_LOGO.png";
import Nim_three from "../../../../assets/images/compi/lyp/Passion.png";
import Part from "../../../../assets/images/compi/lyp/part.jpg";
import Horus from "../../../../assets/images/compi/lyp/horus.jpg";
import SocialTap from "../../../../assets/images/compi/lyp/socila_tape.jpg";
import Thriteen from "../../../../assets/images/compi/lyp/thirteen.jpg";
import Groov from "../../../../assets/images/compi/lyp/groov.jpg";
const Lyps = () => {
  const handleLogo = (url) => {
    window.location.href = url;
  };
  return (
    <div className="faqs_main">
      <p className="faqs_heading">LYP PARTNERS</p>
      <div className="lyp_style">
        <div>
          <div
            onClick={() =>
              handleLogo("https://www.hiphopinternationalindia.com/")
            }
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={HipHop} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://kalaghodaassociation.com/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Kjab} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://www.modelmentor.in/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={MM} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://refttoday.com/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Reft} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div className="lyp_one" style={{ transform: "rotate(4deg)" }}>
            <div className="lyp_one_a">
              <img src={Nim_one} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() =>
              handleLogo("https://www.youtube.com/@humaramovie/videos")
            }
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Hum} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://www.horusmusic.global/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Horus} alt="nitri" className="lyp_logo" />
            </div>
          </div>
        </div>
        <div>
          <div
            onClick={() => handleLogo("https://nsd.gov.in/delhi/")}
            className="lyp_one"
            style={{ transform: "rotate(0deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Drama} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://aerobay.in/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={PA} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://artiumacademy.com/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Logo} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div className="lyp_one" style={{ transform: "rotate(-4deg)" }}>
            <div className="lyp_one_a">
              <img src={Expo} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://www.toastmasters.org/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Toast} alt="nitri" className="lyp_logo" />
            </div>
          </div>

          <div className="lyp_one" style={{ transform: "rotate(-4deg)" }}>
            <div className="lyp_one_a">
              <img src={Nim_three} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://thesocialtape.com/")}
            className="lyp_one"
            style={{ transform: "rotate(2deg)" }}
          >
            <div className="lyp_one_a">
              <img src={SocialTap} alt="nitri" className="lyp_logo" />
            </div>
          </div>

          <div
            onClick={() => handleLogo("https://www.groovenexus.com/")}
            className="lyp_one"
            style={{ transform: "rotate(2deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Groov} alt="nitri" className="lyp_logo" />
            </div>
          </div>
        </div>

        <div>
          <div
            onClick={() => handleLogo("https://www.purplehazestudios.com/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Purple} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://indiaartfestival.com/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={IAF} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://www.jiffindia.org/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={JF} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() => handleLogo("https://www.purplehazestudios.com/")}
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Nim_two} alt="nitri" className="lyp_logo" />
            </div>
          </div>

          <div
            onClick={() => handleLogo("https://www.partymapacademy.com/")}
            className="lyp_one"
            style={{ transform: "rotate(-4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Part} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() =>
              handleLogo("https://www.instagram.com/indianphotofest/")
            }
            className="lyp_one"
            style={{ transform: "rotate(4deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Iphoto} alt="nitri" className="lyp_logo" />
            </div>
          </div>
          <div
            onClick={() =>
              handleLogo("https://www.instagram.com/thirteenzerotwomusic/")
            }
            className="lyp_one"
            style={{ transform: "rotate(-2deg)" }}
          >
            <div className="lyp_one_a">
              <img src={Thriteen} alt="nitri" className="lyp_logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lyps;
