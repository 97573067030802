import React from "react";
import BlogPost from "./BlogPost";
import Marki from "../../../assets/images/ccp/mi_merch.png";
import GO from "../../../assets/images/ccp/go.jpg";
import BO from "../../../assets/images/ccp/background.jpg";

import "./css/mymi.css";

const MyMI = ({ user }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div className="sec_title">WHAT'S UP INDIGO?</div>

      <div className="card" style={{ marginTop: "2vh" }}>
        <div className="content">
          <div className="front">
            <p className="blog_title">
              Travel to Mood Indigo with Goibibo Saving
            </p>
            <div className="blog_image">
              <img src={GO} alt="marki" />
            </div>
          </div>
          <div className="back">
            {/* <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
            <p className="posted">
              <span>Posted On:</span>{" "}
              {new Date(blog.postedOn).toLocaleDateString()}
            </p> */}
            <p>
              Make memories at Mood Indigo and start your journey with Goibibo's
              Go pass. College students can save big with ₹700 off on flights
              and ₹1000 off on hotel bookings. Just use the code GOMOODI to make
              your travel easy and affordable!
            </p>
            <p>Here’s how to claim your discount:</p>
            <p>
              1. Click on the link below and use your college email ID to verify
              yourself as a student on Goibibo <br />
              2. Use the coupon GOMOODI to start enjoying the benefits
            </p>
            <p>
              Link for the same -
              <a
                href="https://app.goibibo.com/mnXF/g7om23le
"
              >
                https://app.goibibo.com/mnXF/g7om23le
              </a>
            </p>

            <p>Book your flight and hotel tickets to Mood Indigo now!!</p>
          </div>
        </div>
      </div>
      <div className="card" style={{ marginTop: "4vh" }}>
        <div className="content">
          <div className="front">
            <p className="blog_title">CCP's Merchandise Design Contest</p>
            <div className="blog_image">
              <img src={Marki} alt="marki" />
            </div>
          </div>
          <div className="back">
            {/* <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
            <p className="posted">
              <span>Posted On:</span>{" "}
              {new Date(blog.postedOn).toLocaleDateString()}
            </p> */}
            <p>
              Showcase your creativity in the 54th Mood Indigo Merch Design
              Contest! Bring “An Enigma of Ethnicity” to life through your
              designs and stand a chance to win exciting rewards, certificates,
              and recognition. Submit your entries by December 3 and make your
              mark on Asia’s largest college cultural festival!
            </p>
            <p>
              Click on the -
              <a href="https://docs.google.com/forms/d/14brpszHEJB0zlMPraQupjUygrr-dPKFAhLRBhnB60HQ/edit">
                Link
              </a>
              - to Register
            </p>
            <p>
              Click on the -
              <a href="https://docs.google.com/forms/d/1WvGhNq9MZw4QkJXSDHTZHsP2YWIjmkECcGCBjw2ZRmg/edit">
                Link
              </a>
              - to submit
            </p>

            <p>
              Click on the -
              <a href="https://chat.whatsapp.com/JeqRhZY4WrwAtgr3ThgWA3 ">
                Link
              </a>
              - to join the WhatsApp Group
            </p>

            <p>
              Be a part of crafting the legacy of 54th Mood Indigo - A
              celebration of creativity, innovation, and unforgettable
              experiences, where every moment becomes a story, and every voice
              shapes the future.
            </p>
          </div>
        </div>
      </div>
      {/* 
      <div className="card" style={{ marginTop: "4vh" }}>
        <div className="content">
          <div className="front">
            <p className="blog_title">CCP's Merchandise Design Contest</p>
            <div className="blog_image">
              <img src={Marki} alt="marki" />
            </div>
          </div>
          <div className="back">
            <h1>Hey Future Smaaash Ambassadors! 🚀</h1>
            <p>
              Ready to level up your college life? Join the SMAAASH Ambassador
              Club, where you can <strong>Play - Connect - Grow!</strong> 🎮
            </p>

            <section className="section">
              <h2>What’s in it for you?</h2>
              <ul className="list">
                <li>
                  🔥 ₹5,000 worth of gaming credits for endless fun at SMAAASH
                  centres!
                </li>
                <li>
                  🎓 Gain beyond-college experiences for real corporate exposure
                  and potential internships!
                </li>
                <li>🎉 VIP event passes to exclusive SMAAASH events!</li>
                <li>
                  💸 Share your ambassador code for exclusive discounts and
                  rewards!
                </li>
                <li>
                  🌟 Boost your leadership, marketing, and event management
                  skills!
                </li>
              </ul>
            </section>

            <section className="process">
              <h2>How to get started?</h2>
              <ol className="steps">
                <li>⿡ Crack 2 quick interviews.</li>
                <li>⿢ Get your welcome kit and game credit points.</li>
                <li>⿣ Start changing the game!</li>
              </ol>
            </section>

            <footer className="footer">
              <a
                href="https://www.esmaaash.com/smaaash-ambassador-club"
                className="apply-button"
              >
                Apply Now
              </a>
              <p>
                This isn’t just a position – it’s a lifestyle upgrade. So, don't
                miss out!
              </p>
            </footer>
          </div>
        </div>
      </div> */}

      <div className="card" style={{ marginTop: "4vh" }}>
        <div className="content">
          <div className="front">
            <p className="blog_title">Smaaash Ambasador Club</p>
            <div className="blog_image">
              <img src={BO} alt="marki" />
            </div>
          </div>
          <div className="back ext_back_mi">
            <header className="header">
              {/* <h1>Hey Future Smaaash Ambassadors! 🚀</h1> */}
              <p>
                Ready to level up your college life? Join the SMAAASH Ambassador
                Club, where you can <strong>Play - Connect - Grow!</strong> 🎮
              </p>
            </header>

            <section className="section">
              <p>What’s in it for you?</p>
              <ul className="list" style={{ textAlign: "justify" }}>
                <li>
                  🔥 ₹5,000 worth of gaming credits for endless fun at SMAAASH
                  centres!
                </li>
                <li>
                  🎓 Gain beyond-college experiences for real corporate exposure
                  and potential internships!
                </li>
                <li>🎉 VIP event passes to exclusive SMAAASH events!</li>
                <li>
                  💸 Share your ambassador code for exclusive discounts and
                  rewards!
                </li>
                <li>
                  🌟 Boost your leadership, marketing, and event management
                  skills!
                </li>
              </ul>
            </section>

            <section className="process">
              <p>How to get started?</p>
              <ol className="steps" style={{ textAlign: "justify" }}>
                <li>Crack 2 quick interviews.</li>
                <li>Get your welcome kit and game credit points.</li>
                <li>Start changing the game!</li>
              </ol>
            </section>

            {/* <footer className="footer"> */}
            <a
              href="https://www.esmaaash.com/smaaash-ambassador-club"
              className="apply-button"
            >
              Apply Now
            </a>
            <p>
              This isn’t just a position – it’s a lifestyle upgrade. So, don't
              miss out!
            </p>
            {/* </footer> */}
          </div>
        </div>
      </div>

      <BlogPost user={user} />
    </div>
  );
};

export default MyMI;
