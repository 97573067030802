import React from "react";

const Competition = () => {
  return (
    <div className="point_main">
      <p className="point_main_heading">
        54th Mood Indigo CCP's Merchandise Design Contest
      </p>
      <p>
        Be a part of crafting the legacy of the 54th Mood Indigo by
        participating in the Merchandise Design Contest - A celebration of
        creativity, innovation, and unforgettable experiences, where every
        moment becomes a story, and every voice shapes the future.
      </p>
      <p>
        <strong>📅 Contest Deadline:</strong> 3rd December, 11:59 PM
      </p>
      <p>
        🔗{" "}
        <a
          href="https://docs.google.com/forms/d/1WvGhNq9MZw4QkJXSDHTZHsP2YWIjmkECcGCBjw2ZRmg/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
        - To Register
      </p>
      <p>
        🔗
        <a
          href="https://docs.google.com/forms/d/1WvGhNq9MZw4QkJXSDHTZHsP2YWIjmkECcGCBjw2ZRmg/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
        - To Submit Your Design
      </p>

      <h3>🎉 Why Participate?</h3>
      <ul>
        <li>
          🥇 Top 3 Designs will be used as references for the official
          merchandise of the 54th Mood Indigo
        </li>
        <li>
          🎨 Receive feedback from the Industrial Design Centre (IDC), IIT
          Bombay
        </li>
        <li>🔑 Appreciation Certificate from Mood Indigo, IIT Bombay</li>
        <li>🎁 Free Official Merchandise of Mood Indigo</li>
      </ul>

      <h3>🗃️ Note:</h3>
      <p>
        <strong>Submitting File Requirements:</strong>
      </p>
      <ul>
        <li>
          Format: AI, PSD, Canva Link (with edit access), Figma Link (with edit
          access)
        </li>
        <li>File Name Format: ParticipantName_MINumber_Moodi2024</li>
      </ul>

      <h3>📤 Submission Process:</h3>
      <ol>
        <li>Upload your design to Google Drive in the specified format.</li>
        <li>
          Share the Drive link (ensure it has editor access) in the Google form.
        </li>
      </ol>

      <p>
        ✨ Let your creativity shine and make your mark on one of Asia’s largest
        cultural festivals! 💥
      </p>
    </div>
  );
};

export default Competition;
